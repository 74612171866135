<template>
  <EnvironmentBanner />

  <MainHeader />

  <main :class="{ 'with-right-sidebar': !!globalState.rightSidebar }">
    <div class="flex-1">
      <RouterView v-slot="{ Component }">
        <div
          :class="{
            container: !$route.meta.layout || $route.meta.layout === 'default',
            'wide-container':
              $route.meta.layout === 'wide' || $route.meta.layout === 'wide-constrained',
            '-constrained': $route.meta.layout === 'wide-constrained'
          }"
          class="!pb-10"
        >
          <component :is="Component" />
        </div>
      </RouterView>
    </div>
    <StickySidebar />
  </main>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import { EnvironmentBanner, MainHeader } from '@/components/ui'
import { useSetupGlobalState } from '@/services/global-state'
import StickySidebar from './components/sticky-sidebar/StickySidebar.vue'

const { globalState } = useSetupGlobalState()
</script>

<style lang="less" scoped>
.with-right-sidebar {
  display: flex;
}
</style>
