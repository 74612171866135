import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

const FORM_SUBMISSIONS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'submissions',
    meta: { title: 'Submissions' },
    children: [
      {
        path: '',
        name: 'submissions',
        component: () => import('@/pages/FormSubmissions/ListPage.vue')
      },
      {
        path: ':submissionId',
        meta: { relatedEntity: 'submission' },
        children: [
          {
            path: '',
            name: 'submission',
            component: () => import('@/pages/FormSubmissions/ShowPage.vue'),
            meta: { layout: 'wide-constrained' }
          }
        ]
      }
    ]
  }
]

export const FORMS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'forms',
    meta: {
      title: 'Forms',
      requiredAbilities: [{ resource: PolicyResourceEnum.Forms, action: 'view' }]
    },
    children: [
      {
        path: '',
        name: 'forms',
        component: () => import('@/pages/Forms/ListPage.vue')
      },
      {
        path: ':formId',
        meta: { relatedEntity: 'form' },
        children: [
          {
            path: '',
            name: 'form',
            meta: {
              layout: 'wide',
              requiredAbilities: [{ resource: PolicyResourceEnum.Forms, action: 'edit' }]
            },
            component: () => import('@/pages/Forms/ShowPage.vue')
          },
          ...FORM_SUBMISSIONS_ROUTES
        ]
      },
      {
        path: 'create',
        name: 'form_create',
        component: () => import('@/pages/Forms/CreatePage.vue'),
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.Forms, action: 'list' }]
        }
      }
    ]
  }
]
