<template>
  <Dropdown trigger="click" placement="bottomRight">
    <div class="flex items-center gap-2 cursor-pointer">
      <PersonAvatar :initials="initials" color="pink" />
      <ArrowIcon direction="down" />
    </div>
    <template #overlay>
      <Menu>
        <div class="px-3 py-2 select-none text-opacity-60 text-black">
          {{ me?.displayName }}
        </div>
        <MenuItem @click="() => logout({ redirectUrl: 'https://auth.worldjewishcongress.org/' })">
          Logout
        </MenuItem>
      </Menu>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import Dropdown from 'ant-design-vue/es/dropdown'
import Menu, { MenuItem } from 'ant-design-vue/es/menu'
import { logout } from '@/services/auth/auth'
import { ArrowIcon, PersonAvatar } from '@/components/ui'
import { getMe } from './getMe.gql'
import { computed } from 'vue'

const { loading, me } = getMe()

const initials = computed(() => {
  if (loading.value) {
    return '..'
  }

  if (!me.value) {
    return '--'
  }

  return me.value?.displayName
    .split(' ')
    .map((name) => name[0])
    .slice(0, 2)
    .join('')
})
</script>
