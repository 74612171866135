import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const LISTS_ROUTES: RouteRecordRaw[] = [
  {
    path: '/lists',
    meta: {
      title: 'Lists',
      requiredAbilities: [{ resource: PolicyResourceEnum.TagLists, action: 'view' }]
    },
    children: [
      { path: '', name: 'lists', component: () => import('@/pages/Lists/ListPage.vue') },
      {
        path: ':listId',
        meta: { relatedEntity: 'list' },
        children: [
          {
            path: '',
            name: 'list',
            meta: { layout: 'wide' },
            component: () => import('@/pages/Lists/ShowPage.vue')
          },
          {
            path: 'edit',
            name: 'list_edit',
            meta: {
              title: 'Edit',
              requiredAbilities: [{ resource: PolicyResourceEnum.TagLists, action: 'list' }]
            },
            component: () => import('@/pages/Lists/EditPage.vue')
          }
        ]
      },
      {
        path: 'create',
        name: 'list_create',
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.TagLists, action: 'list' }]
        },
        component: () => import('@/pages/Lists/CreatePage.vue')
      }
    ]
  }
]
