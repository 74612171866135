<template>
  <div
    v-if="!!state?.rightSidebar"
    class="p-4 sidebar sticky top-0"
    style="max-height: calc(100vh - 66px)"
    :style="{ width: `${size}vw` }"
  >
    <div class="ring-8 m-2 ring-gray-200 rounded max-h-full">
      <GumPanel v-if="!!state?.rightSidebar" class="h-full pr-0">
        <div class="flex flex-row-reverse items-center gap-2 justify-between pb-10 pr-6 h-16">
          <div class="w-28">
            <AntSlider
              :min="20"
              :max="50"
              :step="5"
              v-model:value="size"
              :tipFormatter="(value) => Math.round((((value || 20) - 20) / 30) * 100) + 100 + '%'"
            />
          </div>
          <div class="flex items-center gap-2 justify-end">
            <GumTooltip v-if="showBack" title="Go back">
              <GumBackButton
                type="default"
                size="small"
                narrow
                :routeTo="{ path: state.rightSidebarBackUrl! }"
                iconOnly
              />
            </GumTooltip>

            <GumTooltip title="Close">
              <GumButton type="default" size="small" narrow @click="closeSidebar">
                <template #icon><CloseIcon /></template>
              </GumButton>
            </GumTooltip>
          </div>
        </div>
        <div class="overflow-auto pr-6" style="max-height: calc(100vh - 224px)">
          <component :is="state.rightSidebar" />
        </div>
      </GumPanel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CloseIcon, GumBackButton, GumButton, GumPanel, GumTooltip } from '../ui'
import { useGlobalState, unstickRightSidebar } from '@/services/global-state'
import AntSlider from 'ant-design-vue/es/slider'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const size = ref(20)
const state = useGlobalState()

const closeSidebar = () => {
  unstickRightSidebar()
}

const showBack = computed(
  () => !!state?.value.rightSidebarBackUrl && state.value.rightSidebarBackUrl !== route.fullPath
)
</script>
