import './assets/main.less'

import dayjs from 'dayjs/esm'
import DayJSTimezonePlugin from 'dayjs/esm/plugin/timezone'
import DayJSUtcPlugin from 'dayjs/esm/plugin/utc'

dayjs.extend(DayJSTimezonePlugin)
dayjs.extend(DayJSUtcPlugin)

import { createApp } from 'vue'
import AppShell from './AppShell.vue'
import router from './router'
import { isAuthenticated, authenticate } from '@/services/auth/auth'
import { initSentry } from '@/services/sentry'
import { setupAppApollo } from '@/services/setup-app-apollo'

if (isAuthenticated()) {
  const app = createApp(AppShell)

  initSentry(app, { replies: false })
  setupAppApollo()

  app.use(router)
  app.mount('#app')
} else {
  authenticate()
}
