import type { PolicyResourceEnum } from '@/graphql/app'
import { getPolicies } from './data/getPolicies.gql'

export const usePolicies = () => {
  const { loading, policies } = getPolicies()

  return {
    loading,
    can: (ability: 'list' | 'view' | 'edit', resource: PolicyResourceEnum) =>
      policies.value[resource]?.abilities[ability]
  }
}
