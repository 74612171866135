<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.533 2.66671H13.1996C13.933 2.66671 14.533 3.26671 14.533 4.00004V13.3334C14.533 14.0667 13.933 14.6667 13.1996 14.6667H3.86629C3.12629 14.6667 2.53296 14.0667 2.53296 13.3334L2.53963 4.00004C2.53963 3.26671 3.12629 2.66671 3.86629 2.66671H4.53296V1.33337H5.86629V2.66671H11.1996V1.33337H12.533V2.66671ZM3.86629 6.66671V13.3334H13.1996V6.66671H3.86629ZM13.1996 5.33337H3.86629V4.00004H13.1996V5.33337ZM11.8663 8.66671H8.53296V12H11.8663V8.66671Z"
      fill="#08979C"
    />
  </svg>
</template>
