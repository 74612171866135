<template>
  <Avatar :style="{ 'background-color': backgroundColor }">{{ initials }}</Avatar>
</template>

<script setup lang="ts">
import { Avatar } from 'ant-design-vue/es/components'
import { computed } from 'vue'

const COLORS = {
  'light-blue': '#4096FF',
  'dark-blue': '#597EF7',
  green: '#73D13D',
  turquoise: '#36CFC9',
  pink: '#F759AB',
  purple: '#B37FEB',
  orange: '#FFA940',
  red: '#FF7875'
}
type PossibleColors = keyof typeof COLORS

export interface Props {
  initials: string
  color?: PossibleColors | 'random'
}

const props = withDefaults(defineProps<Props>(), { color: 'random' })

const backgroundColor = computed(() => {
  if (props.color !== 'random') {
    return COLORS[props.color]
  }

  const keys = Object.keys(COLORS) as PossibleColors[]
  const randomIndex = Math.floor(Math.random() * keys.length)

  return COLORS[keys[randomIndex]]
})
</script>
