import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const PEOPLE_ROUTES: RouteRecordRaw[] = [
  {
    path: '/people',
    meta: {
      title: 'People',
      requiredAbilities: [{ action: 'view', resource: PolicyResourceEnum.People }]
    },
    children: [
      {
        path: '',
        name: 'people',
        component: () => import('@/pages/People/ListPage.vue')
      },
      {
        path: ':personId',
        meta: { relatedEntity: 'person' },
        children: [
          {
            path: '',
            name: 'person',
            component: () => import('@/pages/People/ShowPage.vue')
          },
          {
            path: 'edit',
            name: 'person_edit',
            meta: {
              title: 'Edit',
              requiredAbilities: [{ resource: PolicyResourceEnum.People, action: 'list' }]
            },
            component: () => import('@/pages/People/EditPage.vue')
          }
        ]
      },
      {
        path: 'create',
        name: 'person_create',
        component: () => import('@/pages/People/CreatePage.vue'),
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.People, action: 'list' }]
        }
      }
    ]
  }
]
