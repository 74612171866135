import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const TABLES_ROUTES: RouteRecordRaw[] = [
  {
    path: 'tables',
    meta: {
      title: 'Rooms',
      requiredAbilities: [{ resource: PolicyResourceEnum.Rooms, action: 'view' }]
    },
    children: [
      {
        path: '',
        name: 'rooms',
        component: () => import('@/pages/Rooms/ListPage.vue')
      },
      {
        path: ':roomId',
        meta: { relatedEntity: 'room' },
        children: [
          {
            path: '',
            name: 'room',
            meta: {
              layout: 'wide',
              requiredAbilities: [{ resource: PolicyResourceEnum.Rooms, action: 'edit' }]
            },
            component: () => import('@/pages/Rooms/ShowPage.vue')
          }
        ]
      },
      {
        path: 'create',
        name: 'room_create',
        component: () => import('@/pages/Rooms/CreatePage.vue'),
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.Rooms, action: 'edit' }]
        }
      }
    ]
  }
]
