<template>
  <!-- You can see all tokens at https://antdv.com/docs/vue/customize-theme#aliastoken -->
  <AntConfigProvider
    :theme="{
      token: {
        colorPrimary: '#1677ff',
        colorBgLayout: '#f5f5f5',
        fontFamily: 'Inter, sans-serif'
      }
    }"
  >
    <GumSkeleton v-if="loadingPolicies" />
    <App v-else />
  </AntConfigProvider>
</template>

<script setup lang="ts">
import AntConfigProvider from 'ant-design-vue/es/config-provider'
import { useSetupGlobalState } from '@/services/global-state'
import { usePolicies } from './services/policies/usePolicies'
import { GumSkeleton } from '@/components/ui'
import App from './App.vue'

useSetupGlobalState()

const { loading: loadingPolicies } = usePolicies()
</script>
