<template>
  <GumSkeleton v-if="loading" />
  <slot v-else-if="canDo" />
</template>

<script setup lang="ts">
import { PolicyResourceEnum } from '@/graphql/app'
import { usePolicies } from '@/services/policies/usePolicies'
import { GumSkeleton } from '@/components/ui'
import { computed } from 'vue'

const props = defineProps<{
  resource: PolicyResourceEnum
  action: 'list' | 'view' | 'edit'
}>()

const { loading, can } = usePolicies()

const canDo = computed(() => can(props.action, props.resource))
</script>
