<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.36621 4C2.36621 3.44667 2.81288 3 3.36621 3C3.91954 3 4.36621 3.44667 4.36621 4C4.36621 4.55333 3.91954 5 3.36621 5C2.81288 5 2.36621 4.55333 2.36621 4ZM2.36621 8C2.36621 7.44667 2.81288 7 3.36621 7C3.91954 7 4.36621 7.44667 4.36621 8C4.36621 8.55333 3.91954 9 3.36621 9C2.81288 9 2.36621 8.55333 2.36621 8ZM3.36621 11C2.81288 11 2.36621 11.4533 2.36621 12C2.36621 12.5467 2.81954 13 3.36621 13C3.91288 13 4.36621 12.5467 4.36621 12C4.36621 11.4533 3.91954 11 3.36621 11ZM14.6995 12.6667H5.36621V11.3333H14.6995V12.6667ZM5.36621 8.66667H14.6995V7.33333H5.36621V8.66667ZM5.36621 4.66667V3.33333H14.6995V4.66667H5.36621Z"
      fill="#D46B08"
    />
  </svg>
</template>
