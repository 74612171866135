<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.53288 4.66667H15.1995V14H1.86621V2H8.53288V4.66667ZM3.19954 12.6667H4.53288V11.3333H3.19954V12.6667ZM4.53288 10H3.19954V8.66667H4.53288V10ZM3.19954 7.33333H4.53288V6H3.19954V7.33333ZM4.53288 4.66667H3.19954V3.33333H4.53288V4.66667ZM5.86621 12.6667H7.19954V11.3333H5.86621V12.6667ZM7.19954 10H5.86621V8.66667H7.19954V10ZM5.86621 7.33333H7.19954V6H5.86621V7.33333ZM7.19954 4.66667H5.86621V3.33333H7.19954V4.66667ZM8.53288 12.6667H13.8662V6H8.53288V7.33333H9.86621V8.66667H8.53288V10H9.86621V11.3333H8.53288V12.6667ZM12.5329 7.33333H11.1995V8.66667H12.5329V7.33333ZM11.1995 10H12.5329V11.3333H11.1995V10Z"
      :fill="COLORS[color]"
    />
  </svg>
</template>

<script setup lang="ts">
const COLORS = {
  blue: '#0958d9',
  purple: '#722ed1'
}

interface Props {
  color?: 'blue' | 'purple'
}

withDefaults(defineProps<Props>(), { color: 'purple' })
</script>
