import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const EMAILS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'emails',
    meta: {
      title: 'Emails',
      requiredAbilities: [{ resource: PolicyResourceEnum.Emails, action: 'view' }]
    },
    children: [
      {
        path: '',
        name: 'emails',
        component: () => import('@/pages/Emails/ListPage.vue')
      },
      {
        path: ':emailId',
        meta: { relatedEntity: 'email' },
        children: [
          {
            path: '',
            name: 'email_edit',
            component: () => import('@/pages/Emails/EditPage.vue'),
            meta: { requiredAbilities: [{ resource: PolicyResourceEnum.Emails, action: 'list' }] }
          }
        ]
      }
    ]
  }
]
