import type { RouteRecordRaw } from 'vue-router'
import { FORMS_ROUTES } from './forms'
import { EMAILS_ROUTES } from './emails'
import { TABLES_ROUTES } from './tables'
import { PolicyResourceEnum } from '@/graphql/app'

export const EVENTS_ROUTES: RouteRecordRaw[] = [
  {
    path: '/events',
    meta: {
      title: 'Events',
      requiredAbilities: [{ resource: PolicyResourceEnum.Events, action: 'view' }]
    },
    children: [
      { path: '', name: 'events', component: () => import('@/pages/Events/ListPage.vue') },
      {
        path: ':eventId',
        meta: { relatedEntity: 'event' },
        children: [
          {
            path: '',
            name: 'event',
            meta: { layout: 'wide' },
            component: () => import('@/pages/Events/ShowPage.vue')
          },
          {
            path: 'edit',
            name: 'event_edit',
            meta: {
              title: 'Edit',
              requiredAbilities: [{ resource: PolicyResourceEnum.Events, action: 'list' }]
            },
            component: () => import('@/pages/Events/EditPage.vue')
          },
          ...FORMS_ROUTES,
          ...EMAILS_ROUTES,
          ...TABLES_ROUTES
        ]
      },
      {
        path: 'create',
        name: 'event_create',
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.Events, action: 'list' }]
        },
        component: () => import('@/pages/Events/CreatePage.vue')
      }
    ]
  }
]
