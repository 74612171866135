import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const CHANGELOG_ROUTES: RouteRecordRaw[] = [
  {
    path: '/changelog/:type/:id',
    name: 'changelog',
    component: () => import('@/pages/Changelog/ShowPage.vue'),
    meta: {
      requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
    }
  }
]
