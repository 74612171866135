import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const SETTINGS_ROUTES: RouteRecordRaw[] = [
  {
    path: '/settings',
    meta: { title: 'Settings' },
    name: 'settings',
    children: [
      {
        path: 'users',
        name: 'settings_users',
        component: () => import('@/pages/Settings/UsersSettings.vue'),
        meta: {
          title: 'Users',
          requiredAbilities: [{ resource: PolicyResourceEnum.User, action: 'view' }]
        }
      },
      {
        path: 'countries',
        name: 'settings_countries',
        component: () => import('@/pages/Settings/Countries/CountriesSettings.vue'),
        meta: {
          title: 'Countries',
          requiredAbilities: [{ resource: PolicyResourceEnum.Countries, action: 'view' }]
        }
      },
      {
        path: 'room-types',
        name: 'settings_room_types',
        component: () => import('@/pages/Settings/RoomTypes/RoomTypeSettings.vue'),
        meta: {
          title: 'Room Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'tags',
        name: 'settings_tags',
        component: () => import('@/pages/Settings/Tags/TagsSettings.vue'),
        meta: {
          title: 'Tags',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'group-types',
        name: 'settings_group_types',
        component: () => import('@/pages/Settings/GroupTypes/GroupTypesSettings.vue'),
        meta: {
          title: 'Group Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'event-types',
        name: 'settings_event_types',
        component: () => import('@/pages/Settings/EventTypes/EventTypesSettings.vue'),
        meta: {
          title: 'Event Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'capacities',
        name: 'settings_capacities',
        component: () => import('@/pages/Settings/Capacities/CapacitiesSettings.vue'),
        meta: {
          title: 'Capacities',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'relationship-types',
        name: 'settings_relationship_types',
        component: () => import('@/pages/Settings/RelationshipTypes/RelationshipTypesSettings.vue'),
        meta: {
          title: 'Relationship Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'cost-centers',
        name: 'settings_cost_centers',
        component: () => import('@/pages/Settings/CostCenters/CostCentersSettings.vue'),
        meta: {
          title: 'Cost Centers',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'person-titles-types',
        name: 'settings_person_titles',
        component: () => import('@/pages/Settings/PersonTitles/PersonTitlesSettings.vue'),
        meta: {
          title: 'Person Titles',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'contact-types',
        name: 'settings_contact_types',
        component: () => import('@/pages/Settings/ContactKinds/ContactKindsSettings.vue'),
        meta: {
          title: 'Contact Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      },
      {
        path: 'affiliate-types',
        name: 'settings_affiliation_types',
        component: () => import('@/pages/Settings/AffiliationTypes/AffiliationTypesSettings.vue'),
        meta: {
          title: 'Affiliate Types',
          requiredAbilities: [{ resource: PolicyResourceEnum.Others, action: 'view' }]
        }
      }
    ]
  }
]
