import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const INSTITUTIONS_ROUTES: RouteRecordRaw[] = [
  {
    path: '/institutions',
    meta: {
      title: 'Institutions',
      requiredAbilities: [{ resource: PolicyResourceEnum.Institutions, action: 'view' }]
    },
    children: [
      {
        path: '',
        name: 'institutions',
        component: () => import('@/pages/Institutions/ListPage.vue')
      },
      {
        path: ':institutionId',
        meta: { relatedEntity: 'institution' },
        children: [
          {
            path: '',
            name: 'institution',
            component: () => import('@/pages/Institutions/ShowPage.vue')
          },
          {
            path: 'edit',
            name: 'institution_edit',
            meta: {
              title: 'Edit',
              requiredAbilities: [{ resource: PolicyResourceEnum.Institutions, action: 'list' }]
            },
            component: () => import('@/pages/Institutions/EditPage.vue')
          }
        ]
      },

      {
        path: 'create',
        name: 'institution_create',
        meta: {
          title: 'Create',
          requiredAbilities: [{ resource: PolicyResourceEnum.Institutions, action: 'list' }]
        },
        component: () => import('@/pages/Institutions/CreatePage.vue')
      }
    ]
  }
]
