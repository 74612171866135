<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.53304 2.66663C7.05971 2.66663 5.86637 3.85996 5.86637 5.33329C5.86637 6.80663 7.05971 7.99996 8.53304 7.99996C10.0064 7.99996 11.1997 6.80663 11.1997 5.33329C11.1997 3.85996 10.0064 2.66663 8.53304 2.66663ZM9.93304 5.33329C9.93304 4.55996 9.30637 3.93329 8.53304 3.93329C7.75971 3.93329 7.13304 4.55996 7.13304 5.33329C7.13304 6.10663 7.75971 6.73329 8.53304 6.73329C9.30637 6.73329 9.93304 6.10663 9.93304 5.33329ZM12.5997 11.3333C12.5997 10.9066 10.513 9.93329 8.53304 9.93329C6.55304 9.93329 4.46637 10.9066 4.46637 11.3333V12.0666H12.5997V11.3333ZM3.19971 11.3333C3.19971 9.55996 6.75304 8.66663 8.53304 8.66663C10.313 8.66663 13.8664 9.55996 13.8664 11.3333V13.3333H3.19971V11.3333Z"
      :fill="COLORS[color]"
    />
  </svg>
</template>

<script setup lang="ts">
const COLORS = {
  blue: '#1D39C4',
  inherit: 'currentColor'
}

withDefaults(defineProps<{ color?: 'blue' | 'inherit' }>(), { color: 'blue' })
</script>
