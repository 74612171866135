<template>
  <div class="badge-container">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="badge"
    >
      <g clip-path="url(#clip0_3434_31455)">
        <path
          d="M38.3333 19.9997L34.2666 15.3664L34.8333 9.22474L28.8166 7.86641L25.6666 2.56641L20 4.99974L14.3333 2.56641L11.1833 7.86641L5.16663 9.22474L5.73329 15.3664L1.66663 19.9997L5.73329 24.6331L5.16663 30.7747L11.1833 32.1331L14.3333 37.4331L20 34.9997L25.6666 37.4331L28.8166 32.1331L34.8333 30.7747L34.2666 24.6331L38.3333 19.9997ZM21.6666 28.3331H18.3333V24.9997H21.6666V28.3331ZM21.6666 21.6664H18.3333V11.6664H21.6666V21.6664Z"
          fill="#389E0D"
        />
      </g>
      <defs>
        <clipPath id="clip0_3434_31455">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped>
.badge-container {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  position: relative;
  z-index: 10;
}

.badge-container::before {
  content: '';
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: rgba(56, 158, 13, 0.2);
  border-radius: 50%;
  z-index: 5;
  animation: pulse 4s ease-in infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
