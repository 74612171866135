import { PolicyResourceEnum } from '@/graphql/app'
import type { RouteRecordRaw } from 'vue-router'

export const RELEASE_NOTES_ROUTES: RouteRecordRaw[] = [
  {
    path: '/release-notes',
    meta: { title: 'What’s new' },
    children: [
      {
        path: '',
        name: 'release_notes',
        component: () => import('@/pages/ReleaseNotes/ListPage.vue'),
        meta: {
          requiredAbilities: [{ resource: PolicyResourceEnum.ReleaseNotes, action: 'view' }]
        }
      },
      {
        path: ':noteId',
        meta: { relatedEntity: 'releaseNote' },
        children: [
          {
            path: 'edit',
            name: 'release_note_edit',
            meta: {
              title: 'Edit Release Note',
              requiredAbilities: [{ resource: PolicyResourceEnum.ReleaseNotes, action: 'list' }]
            },
            component: () => import('@/pages/ReleaseNotes/EditPage.vue')
          }
        ]
      },
      {
        path: 'create',
        name: 'release_note_create',
        meta: {
          title: 'Add Release Note',
          requiredAbilities: [{ resource: PolicyResourceEnum.ReleaseNotes, action: 'list' }]
        },
        component: () => import('@/pages/ReleaseNotes/CreatePage.vue')
      }
    ]
  }
]
