import type { PolicyResourceEnum } from '@/graphql/app'
import { ref, type Component, type InjectionKey, type Ref, provide, inject, markRaw } from 'vue'

export type GlobalState = {
  rightSidebar: Component | null
  rightSidebarBackUrl: string | null
  can: (ability: 'view' | 'edit', resource: PolicyResourceEnum) => boolean
}

export const globalStateKey = Symbol() as InjectionKey<Ref<GlobalState>>

const globalState = ref<GlobalState>({
  rightSidebar: null,
  rightSidebarBackUrl: null,
  can: () => false
})

export const useSetupGlobalState = () => {
  provide(globalStateKey, globalState)

  return { globalState }
}

export const useGlobalState = () => inject(globalStateKey)

export const unstickRightSidebar = () => {
  globalState.value.rightSidebar = null
  globalState.value.rightSidebarBackUrl = null
}

export const stickToRightSidebar = (
  component: Component,
  backUrl: string | null = window.location.pathname
) => {
  globalState.value.rightSidebar = markRaw(component)
  globalState.value.rightSidebarBackUrl = backUrl
}
